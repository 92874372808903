import React , {useState, useEffect} from 'react';
import { useNavigate , NavLink, Link } from 'react-router-dom';
import { useDispatch , useSelector } from "react-redux"
import OtherNavigation from '../../Includes/OtherNavigation'
import { actionConfig } from '../../configuration';
import { SingleNoticeBoard } from '../../redux/action/SingleNoticeBoard';
import Swal from 'sweetalert2';
import { AttendanceACtion } from '../../redux/action/AttendanceACtion';
import ReactPaginate from 'react-paginate';
import moment from 'moment/moment';
import { getDashCountData } from '../../redux/action/DashboardCountAction';

const HrDashboard = () => {

const resultRolePermssion = useSelector(state => state.Permissiondatareducers.singledataredu);
const resultListing = useSelector(state => state.Attendancereducers.singleattendancedata);
const resulthrlisting = useSelector(state => state.dashCountReducer.dashboardcount);
const dispatch = useDispatch();
const DepartmentList = resultRolePermssion === '' ? '' : resultRolePermssion.filter(edx => edx.feature_id === 68 && edx.sub_features === 'List');
const userData = JSON.parse(localStorage.getItem('authdata'));
const finalUserData = JSON.stringify(userData);
useEffect(() => {
const AuthData = JSON.parse(localStorage.getItem('authdata'));
dispatch(SingleNoticeBoard(AuthData.id));
},[]);
var CurrentDate = moment().format('YYYY-MM-DD');

const [EmployeeName,setEmployeeName]=useState('');

useEffect(() => {
const AuthData = JSON.parse(localStorage.getItem('authdata'));
if(DepartmentList.length === 0 || DepartmentList === '' || DepartmentList === null){
dispatch(AttendanceACtion(AuthData.id,1,12,''));
}else{
dispatch(AttendanceACtion('all',1,12,EmployeeName));
}
const interval = setInterval(() => {

}, 3000);
return () => clearInterval(interval);
},[]);


useEffect(() => {
const AuthData = JSON.parse(localStorage.getItem('authdata'));
if(DepartmentList.length === 0 || DepartmentList === '' || DepartmentList === null){
dispatch(getDashCountData(AuthData.id));
}else{
dispatch(getDashCountData('all'));
}
},[]);


const handlePageClick = (data) => {
let currentPage = data.selected + 1
const AuthData = JSON.parse(localStorage.getItem('authdata'));
if(DepartmentList.length === 0 || DepartmentList === '' || DepartmentList === null ){
dispatch(AttendanceACtion(AuthData.id,currentPage,12,''));
}else{
dispatch(AttendanceACtion('all',currentPage,12,EmployeeName));
}

}

const AttendanceAction = (status) => {

const AuthData = JSON.parse(localStorage.getItem('authdata'));
console.log("resulthrlistingcLICK",resulthrlisting);
if(resulthrlisting.todayCheckInData == null || resulthrlisting.todayCheckInData == ''){

Swal.fire({
title: `Do you want to Check${status}?`,
// showDenyButton: true,
showCancelButton: true,
// confirmButtonText: 'Yes',
}).then((result) => {
if (result.isConfirmed) {

const FeildData = { emp_id:AuthData === '' || AuthData === null ? '' : AuthData.id,attendance_date:CurrentDate,checkintype:status === 'OUT' ? '-' : 'IN',checkouttype:status==='IN' ? '-' : 'OUT' }

const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(FeildData)
};

fetch(`${actionConfig.REACT_APP_URL}attendance`, requestOptions)
.then(response => response.json())
.then(dataexe => {
  console.log(dataexe);
    if(dataexe.code == '200'){
      Swal.fire(
        'Good job!',
        dataexe.message,
        'success'
      );

      if(DepartmentList.length === 0 || DepartmentList === '' || DepartmentList === null){
        dispatch(AttendanceACtion(AuthData.id,1,12,''));
        dispatch(getDashCountData(AuthData.id));
        }else{
          dispatch(AttendanceACtion('all',1,12,EmployeeName));
          dispatch(getDashCountData('all'));
        }
        
    }else if(dataexe.code === '201'){
      Swal.fire(
        'Error!',
        dataexe.message,
        'error'
      );
    }else{
      Swal.fire(
        'Error!',
        dataexe.message,
        'error'
      );
    }
});
} else if (result.isDenied) {
Swal.fire('Changes are not saved', '', 'info')
}
})

}else{
Swal.fire(
'Warning!',
'Checkin Already Exists',
'warning'
);
}


}

const AttendanceCheckoutAction = (status) => {
const AuthData = JSON.parse(localStorage.getItem('authdata'));
console.log("resulthrlistingCehck",resulthrlisting);
if(resulthrlisting.todayCheckInData == null || resulthrlisting.todayCheckInData == ''){
Swal.fire(
'Warning!',
'Please CheckIn First Then You Can Perform Checkout',
'warning'
);
}else if(resulthrlisting.todayCheckOutData == null || resulthrlisting.todayCheckOutData == ''){
Swal.fire({
title: `Do you want to Check${status}?`,
// showDenyButton: true,
showCancelButton: true,
// confirmButtonText: 'Yes',
}).then((result) => {
if (result.isConfirmed) {

const FeildData = { emp_id:AuthData === '' || AuthData === null ? '' : AuthData.id,checkouttype:status,checkinId:resulthrlisting == '' || resulthrlisting == null ? '' : resulthrlisting.todayCheckInData.id}

const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(FeildData)
};

fetch(`${actionConfig.REACT_APP_URL}attendancecheckout`, requestOptions)
.then(response => response.json())
.then(dataexe => {
  console.log(dataexe);
    if(dataexe.code == '200'){
      Swal.fire(
        'Good job!',
        dataexe.message,
        'success'
      );

      if(DepartmentList.length === 0 || DepartmentList === '' || DepartmentList === null){
        dispatch(AttendanceACtion(AuthData.id,1,12,''));
        dispatch(getDashCountData(AuthData.id));
        }else{
          dispatch(AttendanceACtion('all',1,12,''));
          dispatch(getDashCountData('all'));
        }
        
    }else if(dataexe.code === '201'){
      Swal.fire(
        'Error!',
        dataexe.message,
        'error'
      );
    }else{
      Swal.fire(
        'Error!',
        dataexe.message,
        'error'
      );
    }
});
} else if (result.isDenied) {
Swal.fire('Changes are not saved', '', 'info')
}
})
}else{
Swal.fire(
'Warning!',
'Checkout Already Exists!',
'warning'
);
}

}

const handleSearch = async (e) => {
  e.preventDefault();
  const AuthData = JSON.parse(localStorage.getItem('authdata'));
  if(DepartmentList.length === 0 || DepartmentList === '' || DepartmentList === null){
    dispatch(AttendanceACtion(AuthData.id,1,12,''));
  }else{
    dispatch(AttendanceACtion('all',1,12,EmployeeName));
  }
  // dispatch(ChequeOutwardAction(1,12,IssueDate,ChequeDate,ChequeNo,ChequeStatus,AccountNo,ChequeAmount,Title));
}

console.log("resulthrlisting",resulthrlisting);
return (
<>
<div className="container-fluid mt-xl-50 mt-sm-30 mt-15 pt-4">
<div className="hk-pg-header pt-4 pb-4 mt-2 mb-3 d-flex justify-content-between">
<div>
<h2 className="hk-pg-title font-weight-600">Dashboard</h2>
</div>

</div>

<OtherNavigation/>

<div className="hk-row">

<div className="col-lg-3 col-sm-6 col-6">
<div className="card card-sm">
<div className="card-body">
<div className="align-items-center d-flex justify-content-between">
<a href="javascript:;"> <span className="d-block font-11 font-weight-500 text-dark text-uppercase mb-10">Today Check In</span> </a>
</div>
<div className="d-flex align-items-center justify-content-between position-relative">
<div> <span className="d-block">
<span className="display-5 font-weight-400 text-dark"><span className="counter-anim">
{DepartmentList.length == 0 || DepartmentList == '' || DepartmentList == null ? `${resulthrlisting.checkintimetoday == null || resulthrlisting.checkintimetoday == '' ? '00:00' : resulthrlisting.checkintimetoday}`:`${resulthrlisting.todayCheckIn == '' || resulthrlisting.todayCheckIn == null ? '0':resulthrlisting.todayCheckIn}`}</span></span>
</span>
</div>
</div>
</div>
</div>
</div>
<div className="col-lg-3 col-sm-6 col-6">
<div className="card card-sm">
<div className="card-body">
<div className="align-items-center d-flex justify-content-between">
<a href="javascript:;"> <span className="d-block font-11 font-weight-500 text-dark text-uppercase mb-10">Today Check Out</span> </a>
</div>
<div className="d-flex align-items-center justify-content-between position-relative">
<div> <span className="d-block">
<span className="display-5 font-weight-400 text-dark"><span className="counter-anim">{DepartmentList.length == 0 || DepartmentList == '' || DepartmentList == null ? `${resulthrlisting.checkouttimetoday == null || resulthrlisting.checkouttimetoday == '' ? '00:00' : resulthrlisting.checkouttimetoday}`:`${resulthrlisting.todayCheckOut == '' || resulthrlisting.todayCheckOut == null ? '0':resulthrlisting.todayCheckOut}`}</span></span>
</span>
</div>
</div>
</div>
</div>
</div>
<div className="col-lg-3 col-sm-6 col-6">
<div className="card card-sm">
<div className="card-body">
<div className="align-items-center d-flex justify-content-between"> <span className="d-block font-11 font-weight-500 text-dark text-uppercase mb-10">Present This Month</span> </div>
<div className="d-flex align-items-center justify-content-between position-relative">
<div> <span className="d-block">
<span className="display-5 font-weight-400 text-dark"><span className="counter-anim">{resulthrlisting.monthlyAttendance == null || resulthrlisting.monthlyAttendance == '' ? '0' : resulthrlisting.monthlyAttendance}</span></span>
</span>
</div>
</div>
</div>
</div>
</div>
<div className="col-lg-3 col-sm-6 col-6">
<div className="card card-sm">
<div className="card-body">
<div className="align-items-center d-flex justify-content-between"> <span className="d-block font-11 font-weight-500 text-dark text-uppercase mb-10">Late This Month</span> </div>
<div className="d-flex align-items-end justify-content-between">
<div> <span className="d-block">
<span className="display-5 font-weight-400 text-dark">0</span> </span>
</div>
<div> </div>
</div>
</div>
</div>
</div>

</div>


{
DepartmentList.length === 0 || DepartmentList === '' || DepartmentList === null ? (
<div className="hk-row">
<div className="col-md-12">
<ul className='d-flex pt-4' style={{justifyContent:'center',gap:10}}>

<li><a href="javascript:;" className='btn btn-primary' 
onClick={(e)=>AttendanceAction('IN')} 
style={{paddingLeft:30,paddingRight:30}}
>Check In</a></li>

<li><a href="javascript:;" className='btn btn-primary' style={{paddingLeft:30,paddingRight:30,background:'red',borderColor:'red'}} 
onClick={(e)=>AttendanceCheckoutAction('OUT')} 
>Check Out</a></li>
</ul>
</div>
</div>
):(
<></>
)
}

<div className="row">
<div className="col-md-12">
<form>
<div className="row align-items-center">
<div className="form-group col-md-3">
<label>Employees</label>
<select className='form-control' name="EmployeeName" onChange={e=>setEmployeeName(e.target.value)} value={EmployeeName}>
  <option value="">Select Employees</option>
  {
   resulthrlisting.length == 0 || resulthrlisting.employee.length == 0 || resulthrlisting.employee == '' || resulthrlisting.employee == undefined || resulthrlisting.employee == undefined ? (
      <></>
    ):(
      resulthrlisting.employee.map((curElem)=>{
        return (
          <option value={curElem.id}>{curElem.emp_name}</option>
        )
      })
    )
  }
</select>
</div>
<div className="form-group col-md-2 m-0">
<button type='submit' name="find" className='btn btn-primary mt-3' onClick={handleSearch}>Find</button>
</div>
</div>
</form>
</div>
</div>

<div className="row">
<div className="col-sm">
<h4>Attendance List</h4>
<div className="table-wrap">
<table id="datable_1" className="table table-hover w-100 display pb-30">
<thead className="thead-dark">
<tr>
<th>ID</th>
<th>Emp Name</th>
<th>Checkin</th>
<th>Checkout</th>
<th>Total Hours</th>
<th>Date</th>
</tr>
</thead>
<tbody>

{

resultListing === null || resultListing.code === 'ERR_BAD_RESPONSE' || resultListing.code === 'ERR_NETWORK' ? (
<>Loading.....</>
):(

resultListing.data && resultListing.data.map((curElem , index) => {

return (
<tr>
<td>{curElem.id}</td>
<td>{curElem.emp_name}</td>
<td>{curElem.checkin}</td>
<td>{curElem.checkout}</td>
<td>{curElem.duration}</td>
<td>{curElem.attendance_date}</td>
</tr>
)
})
)

}

</tbody>
</table>

<ReactPaginate 
previousLabel={`previous`}
nextLabel={`next`}
breakLabel={`...`}
pageCount={Math.ceil(resultListing.TotalCount/12)}
marginPagesDisplayed={3}
pageRangeDisplayed={3}
onPageChange={handlePageClick}
containerClassName={`pagination justify-content-center`}
pageClassName={`page-item`}
pageLinkClassName={`page-link`}
previousClassName={`page-item`}
previousLinkClassName={`page-link`}
nextLinkClassName={`page-link`}
nextClassName={`page-item`}
breakLinkClassName={`page-link`}
breakClassName={`page-item`}
activeClassName={`active`}
/>
</div>
</div>
</div>

</div>
</>
)
}

export default HrDashboard