import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { SingleEmpAction } from '../../redux/action/SingleEmpAction';
import { useNavigate } from 'react-router-dom';
import 'bootstrap-daterangepicker/daterangepicker.css';
import HrDashboard from '../Hr/HrDashboard';

const Dashboard = () => {

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const resultlistdashboard = useSelector(state => state.dashboardListReducers.dashboardlistcount);

  useEffect(() => {
    if (!localStorage.getItem('authdata')) {
      navigate('/');
    }
  }, [navigate]);


  return (
    <>
    <HrDashboard />
    </>
  )
}

export default Dashboard