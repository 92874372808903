export const actionType ={
    DASHBOARDHR_COUNT:"DASHBOARDHR_COUNT",
    ASSIGN_LEADS:"ASSIGN_LEAD_DATA",
    VIRGIN_LEADS:"VIRGIN_LEAD_DATA",
    MYLEADS:"MY_LEAD_DATA",
    HOTLEADS:"HOT_LEAD_DATA",
    REMINDERLEADS:"REMINDER_LEAD_DATA",
    ACTIVITIESLEADS:"ACTIVITIES_LEAD_DATA",
    NOTINTERESTEDLEADS:"NOTINTERESTED_LEAD_DATA",
    INVENTORIES:"INVENTORIES_DATA",
    SINGLEINVENTORIES:"SINGLE_INVENTORIES_DATA",
    LISTINVENTORIES:"LIST_INVENTORIES_DATA",
    SINGLEPROINVENTORIES:"SINGLE_PRO_INVENTORIES_DATA",
    LISTFORMDATA:"LIST_FORM_DATA",
    SINGLEMYLEAD:"SINGLE_MY_LEAD",
    NOTINTERESTEDREASONOPT:"NOT_INTERESTED_OPT",
    CALLINGACTION:"CALLING_ACTION",
    NOTICEBOARDACTION:"NOTICE_BOARD_ACTION",
    VIDEOCATACTION:"VIDEOCAT",
    EDUCATIONSACTION:"EDUCATIONS",
    SINGLEEMPLOYEEACTION:"SINGLE_EMPLOYEE_ACTION",
    VIDEOFORMACTION:"VIDEO_FORM_ACTION",
    SINGLEVIDEOREQ:"VIDEO_SINGLE_ACTION",
    ROLECATEGORYACTION:"ROLE_CATEGORY_ACTION",
    IMPORTLEADSACTION:"IMPORT_LEADS_ACTION",
    ROLEMODULEACTION:"ROLE_MODULE_ACTION",
    ROLEPERMISSIONMODULE:"ROLE_PERMISSION_MODULE_ACTION",
    AFFILIATELEADSMODULE:"AFFILIATE_LEADS_ACTION",
    SINGLEAFFILIATELEADSMODULE:"SINGLE_AFFILIATE_LEADS_ACTION",
    SINGLEAFFILIATEACTIVITY:"SINGLE_AFFILIATE_ACTIVITY_ACTION",
    DEALSDONEACTION:"DEALS_DONE_ACTION",
    TASKMANAGEMENTACTION:"TASK_MANAGEMENT_ACTION",
    ATTENDANCEACTION:"ATTENDANCE_ACTION",
    SINGLEATTENDANCEACTION:"SINGLE_ATTENDANCE_ACTION",
    EMPLOYEEROLESACTION:"EMPLOYEE_ROLES_ACTION",
    SINGLENOTICEBOARD:"SINGLE_NOTICE_BOARD",
    SINGLEROLEPERMISSIONDATA:"SINGLE_ROLE_PERMISSION_DATA",
    IMPORTEMPLOYEEACTION:"IMPORT_EMPLOYEE_ACTION",
    PERMISSIONDATAACTION:"PERMISSION_DATA_ACTION",
    ZONGCALLINGDATAACTION:"ZONG_CALLING_DATA_ACTION",
    TRANSFERLEADSACTION:"TRANSFER_LEADS_ACTION",
    SINGLEASSIGNLEADSACTION:"SINGLE_ASSIGN_LEADS_ACTION",
    HREMPLOYEESACTION:"HR_EMPLOYEE_ACTION",
    ANALYTICSACTION:"ANALYTICS_ACTION",
    DAILYLEADSREPORTING:"DAILY_LEADS_REPORTING",
    SUPERDATABASEACTION:"SUPER_DATABASE_ACTION",
    WHATSAPPACTIVITIESACTION:"WHATSAPP_ACTIVITIES_ACTION",
    SECURITYFILESACTION:"SECURITY_FILES_ACTION",
    PAYROLL_ACTION:"PAYROLL_ACTION",
    BANK_LIST_ACTION:"BANK_LIST_ACTION",
    STOCKREGISTERACTION:"STOCK_REGISTER_ACTION",
    LISTINVENTORIESFILTERS:"LIST_INVENTORIES_FILTERS",
    SINGLEINVENTORIESSOLD:"SINGLE_INVENTORIES_SOLD",
    PRICINGACTION:"PRICING_ACTION",
    ALLLISTINVENTORIES:"ALL_LISTING_INVENTORIES_ACTION",
    ALLBLOCKWISEPLOT:"ALL_BLOCK_WISE_PLOT",
    ALLPLOTWISESIZE:"ALL_PLOT_WISE_SIZE",
    ALLPAYMENTRECIEPTDATA:"ALL_PAYMENT_RECIEPT_DATA",
    PAYMENTPLANACTION:"PAYMENT_PLAN_ACTION",
    ASSETLISTACTION:"ASSET_LIST_ACTION",
    CHEQUELISTACTION:"CHEQUE_LIST_ACTION",
    CHEQUEOUTWARDLISTACTION:"CHEQUE_OUTWARD_LISTACTION",
    SECURITYFILTERSACTION:"SECURITY_FILTERS_ACTION",
    POSTDATEDCHEQUEACTION:"POST_DATED_CHEQUE_ACTION",
    CHEQUEOUTWARDFILTERSACTION:"CHEQUE_OUTWARD_FILTERS_ACTION",
    POSTDATEDCHEQUEFILTERSACTION:"POST_DATED_CHEQUE_FILTERS_ACTION",
    PAYMENTVOUCHERACTION:"PAYMENT_VOUCHER_ACTION",
    PAYRECIEPTSACTION:"PAY_RECIEPTS_ACTION",
    FIXEDASSETFILTERSACTION:"FIXED_ASSET_FILTERS_ACTION",
    STOCKFILTERSACTION:"STOCK_FILTERS_ACTION",
    PAYMENTRECIEPTFILTERACTION:"PAYMENT_RECIEPT_FILTER_ACTION",
    PAYMENTVOUCHERFILTERACTION:"PAYMENT_VOUCHER_FILTER_ACTION",
    SALESFORMACTION:"SALES_FORM_ACTION",
    LEADSTATUSREPORTACTION:"LEAD_STATUS_REPORT_ACTION",
    LISTSTATUSREPORTACTION:"LIST_STATUS_REPORT_ACTION",
    CALLSTATUSREPORTACTION:"CALL_STATUS_REPORT_ACTION",
    LEADIMPORTDATAACTION:"LEAD_IMPORT_DATA_ACTION",
    KPIREPORTS:"KPI_REPORTS",
    LEADSTATUSLOADER:"LEAD_STATUS_LOADER",
    LEADSTATUSHIDELOADER:"LEAD_STATUS_HIDE_LOADER",
    LOADINGTOGGLEACTION:"LOADING_TOGGLE_ACTION",
    DASHBOARDLISTACTION:"DASHBOARD_LIST_ACTION",
    PETTYCASHACTION:"PETTY_CASH_ACTION",
    LEADHISTORYACTION:"LEAD_HISTORY_ACTION",
    TLWLEADACTION:"TLW_LEAD_ACTION",
    FINDASHBOARDLIST:"FIN_DASHBOARD_LIST",
    CASHINFLOWACTION:"CASH_INFLOW_ACTION",
    BANKFLOWACTION:"BANK_FLOW_ACTION",
    EMPLOYEEMANAGERACTION:"EMPLOYEE_MANAGER_ACTION",
    PETTYCASHINFLOWACTION:"PETTY_CASH_INFLOW_ACTION",
    TALKTIMEACTION:"TALK_TIME_ACTION",
    BANK_DEPOSITS_ACTION:"BANK_DEPOSITS_ACTION",
    TASKMANAGEMENTSELFACTION:"TASK_MANAGEMENT_SELF_ACTION",
    BANKDEPOSITFLOWACTION:"BANK_DEPOSIT_FLOW_ACTION",
    ASSETCOMPANYFLOWACTION:"ASSETCOMPANYFLOWACTION",
    ASSETDEPARTMEMTFLOWACTION:"ASSETDEPARTMEMTFLOWACTION",
    ASSETTYPEFLOWACTION:"ASSETTYPEFLOWACTION",
    PURCHASEREQFORM:"PURCHASEREQFORM",
    ASSETLOCATIONFLOWACTION:"ASSETLOCATIONFLOWACTION",
    FINBILLSACTION:"FINBILLSACTION",
    CIRCULEPOLICY:"CIRCULEPOLICY",
    COMPLAINMANAGEMENTACTION:"COMPLAINMANAGEMENTACTION",
    CHARTLEVEL1:"CHARTLEVEL1",
    CHARTLEVEL2:"CHARTLEVEL2",
    CHARTLEVEL3:"CHARTLEVEL3",
    DIMENSIONLEVEL1:"DIMENSIONLEVEL1",
    DIMENSIONLEVEL2:"DIMENSIONLEVEL2",
    DIMENSIONLEVEL3:"DIMENSIONLEVEL3",
    GENERATEMAPPINGACTION:"GENERATEMAPPINGACTION",
    BILLSUMMARYACTION:"BILLSUMMARYACTION",
    BILLDUESUMMARYACTION:"BILLDUESUMMARYACTION",
    VOUCHERTYPEACTION:"VOUCHERTYPEACTION",
}