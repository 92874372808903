import { actionConfig } from "../../configuration";
import { actionType } from "../types/types"
import axios from 'axios';

export const getDashCountData = (agentId) =>{

return async function (dispatch,getState){
 
 const CancelToken = axios.CancelToken;
 const source = CancelToken.source();
 
  const response = await axios(`${actionConfig.REACT_APP_URL}hrdashboardlist?agentId=${agentId}`,{cancelToken: source.token}).then((res)=>{
     return res.data;
  }).catch((thrown) => {
     return thrown;
  });
dispatch(
{
    type:actionType.DASHBOARDHR_COUNT,
    payload:response,
}
)

}

}