import React from 'react';
// import { useDispatch , useSelector } from "react-redux";
import { BrowserRouter , Routes , Route } from "react-router-dom";
import Dashboard from './Components/SalesDashboard/Dashboard';
import Login from './Components/SalesDashboard/Login';

import HrDashboard from './Components/Hr/HrDashboard';
import Profile from './Components/UserProfile/Profile';

//Hr Modules
import Companies from './Components/Hr/Companies';
import AddCompanies from './Components/Hr/AddCompanies';

import Employees from './Components/Hr/Employees';
import ReportTo from './Components/Hr/ReportTo';
import AddNewEmployee from './Components/Hr/AddNewEmployee';

import Leave from './Components/Hr/Leave';
import AddLeave from './Components/Hr/AddLeave';
import LeaveType from './Components/Hr/LeaveType';

import Notice from './Components/Hr/Notice';
import AddNotice from './Components/Hr/AddNotice';

import Tranining from './Components/Hr/Tranining';
import AddNewTraining from './Components/Hr/AddNewTraining';
import AddTrainingType from './Components/Hr/AddTrainingType';

import Warning from './Components/Hr/Warning';
import AddWarning from './Components/Hr/AddWarning';

import Attendance from './Components/Hr/Attendance';
import AddAttendance from './Components/Hr/AddAttendance';

import Branch from './Components/Hr/Branch';
import AddBranch from './Components/Hr/AddBranch';

import Departments from './Components/Hr/Departments';
import AddDepartments from './Components/Hr/AddDepartments';

import Designations from './Components/Hr/Designations';
import AddDesignation from './Components/Hr/AddDesignation';

import Payrolls from './Components/Hr/Payrolls';
import Setups from './Components/Hr/Setups';

import Termination from './Components/Hr/Termination';
import AddTermination from './Components/Hr/AddTermination';

import JobPost from './Components/Hr/JobPost';
import AddJobPost from './Components/Hr/AddJobPost';

import RoleCategory from './Components/RolesPermission/RoleCategory';
import CrmModules from './Components/RolesPermission/CrmModules';
import RolePermission from './Components/RolesPermission/RolePermission';

import CustomizePermission from './Components/RolesPermission/CustomizePermission';
import ModuleSetup from './Components/RolesPermission/ModuleSetup';
import ResetPassword from './Components/UserProfile/ResetPassword';
import ForgotPassword from './Components/UserProfile/ForgotPassword';


import NewAddEmployee from './Components/Hr/NewAddEmployee';
import EmployeeShift from './Components/Hr/EmployeeShift';
import AddEmployeeShift from './Components/Hr/AddEmployeeShift';
import Holiday from './Components/Hr/Holiday';
// import { requestForToken,onMessageListener } from './firebase';
// import { useState } from 'react';
// import { onMessageListener } from './firebase';
import SignUp from './Components/UserProfile/SignUp';
import AddNewReportTo from './Components/Hr/AddNewReportTo';

const App = () => {

  // const [notification, setNotification] = useState({title: '', body: ''});

  // onMessageListener()
  //   .then((payload) => {
  //     setNotification({title: payload?.notification?.title, body: payload?.notification?.body});     
  //   })
  //   .catch((err) => console.log('failed: ', err));
  
  return (
    <>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login/>} />
          <Route path="/sign-up" element={<SignUp/>} />
          <Route path="/dashboard" element={<Dashboard/>} />
          <Route path="hr" exact element={<HrDashboard/>} />
          
          <Route path="profile" exact element={<Profile/>} />

          {/* Hr Modules Routes */}

          <Route path="company" exact element={<Companies/>} />
          <Route path="add-companies" exact element={<AddCompanies/>} />
          <Route path="add-companies/:id" exact element={<AddCompanies/>} />
          
          <Route path="employee" exact element={<Employees/>} />
          <Route path="add-new-employee" exact element={<AddNewEmployee/>} />
          <Route path="add-new-employee/:id" exact element={<AddNewEmployee/>} />

          <Route path="report-to" exact element={<ReportTo/>} />
          <Route path="add-new-report-to" exact element={<AddNewReportTo/>} />
          <Route path="add-new-report-to/:id" exact element={<AddNewReportTo/>} />
          
          <Route path="add-new-employees" exact element={<NewAddEmployee/>} />
          <Route path="add-new-employees/:id" exact element={<NewAddEmployee/>} />

          <Route path="leave" exact element={<Leave/>} />
          <Route path="add-leave-request" exact element={<AddLeave/>} />
          <Route path="add-leave-request/:id" exact element={<AddLeave/>} />
          <Route path="leave-type" exact element={<LeaveType/>} />
          <Route path="leave-type/:id" exact element={<LeaveType/>} />
          

          <Route path="training" exact element={<Tranining/>} />
          <Route path="add-new-training" exact element={<AddNewTraining/>} />
          <Route path="add-new-training/:id" exact element={<AddNewTraining/>} />

          <Route path="add-training-type" exact element={<AddTrainingType/>} />
          <Route path="add-training-type/:id" exact element={<AddTrainingType/>} />

          
          
          <Route path="notice" exact element={<Notice/>} />
          <Route path="add-new-notice" exact element={<AddNotice/>} />
          <Route path="add-new-notice/:id" exact element={<AddNotice/>} />

          <Route path="warning" exact element={<Warning/>} />
          <Route path="add-new-warning" exact element={<AddWarning/>} />
          <Route path="add-new-warning/:id" exact element={<AddWarning/>} />

          <Route path="attendance" exact element={<Attendance/>} />
          <Route path="add-attendance" exact element={<AddAttendance/>} />
          <Route path="add-attendance/:id" exact element={<AddAttendance/>} />

          <Route path="branch" exact element={<Branch/>} />
          <Route path="add-branch" exact element={<AddBranch/>} />
          <Route path="add-branch/:id" exact element={<AddBranch/>} />
          
          
          
          <Route path="department" exact element={<Departments/>} />
          <Route path="add-department" exact element={<AddDepartments/>} />
          <Route path="add-department/:id" exact element={<AddDepartments/>} />

          <Route path="employee-shift" exact element={<EmployeeShift/>} />
          <Route path="add-employee-shift" exact element={<AddEmployeeShift/>} />
          <Route path="add-employee-shift/:id" exact element={<AddEmployeeShift/>} />

          <Route path="holiday" exact element={<Holiday/>} />
          <Route path="holiday/:id" exact element={<Holiday/>} />

          <Route path="designation" exact element={<Designations/>} />
          <Route path="add-designation" exact element={<AddDesignation/>} />
          <Route path="add-designation/:id" exact element={<AddDesignation/>} />
          

          <Route path="payroll" exact element={<Payrolls/>} />
          <Route path="setups" exact element={<Setups/>} />

          <Route path="termination" exact element={<Termination/>} />
          <Route path="add-termination" exact element={<AddTermination/>} />
          <Route path="add-termination/:id" exact element={<AddTermination/>} />

          <Route path="job-post" exact element={<JobPost/>} />
          <Route path="add-job-post" exact element={<AddJobPost/>} />
          <Route path="add-job-post/:id" exact element={<AddJobPost/>} />
          <Route path="role-category" exact element={<RoleCategory/>} />
          <Route path="role-category/:id" exact element={<RoleCategory/>} />

          <Route path="crm-modules" exact element={<CrmModules/>} />
          <Route path="crm-modules/:id" exact element={<CrmModules/>} />
          <Route path="role-permission" exact element={<RolePermission/>} />
          <Route path="customize-permission" exact element={<CustomizePermission/>} />
          <Route path="module-setup" exact element={<ModuleSetup/>} />

          <Route path="reset/:id" exact element={<ResetPassword/>} />
          <Route path="forgot-password" exact element={<ForgotPassword/>} />

        </Routes>
      </BrowserRouter>
      
    </>
  );
}

export default App;
